<template>
  <section class="OurClients">
    <div
      class="OurClients-Item"
      v-for="(client, i) in clients"
      :key="i"
    >
      <div class="OurClients-Logo">
        <img
            :src="require(`@/assets/images/${client.logo}`)"
            alt="logo"
            class="OurClients-LogoImg"
        >
      </div>

      <div class="OurClients-Info">
        <div class="OurClients-InfoHeader">
          <img
              :src="require(`@/assets/images/${client.logo}`)"
              alt="logo"
              class="OurClients-InfoLogo"
          >
          <div class="OurClients-Title">
            {{ client.info.title }}
          </div>

          <div class="OurClients-Flags">
            <div
              class="OurClients-FlagsItem"
              v-for="(flag, f) in client.info.flags"
              :key="'B' + f"
              :style="'background-image: url(' + require(`@/assets/images/${flag}`) + ')'"
            ></div>
          </div>
          <div class="OurClients-FlagsInfo" v-if="client.info.flagsText">{{ client.info.flagsText }}</div>
        </div>

        <dl class="OurClients-InfoInfo">
          <div
              class="OurClients-InfoInfoItem"
              v-for="(info, $info) in client.info.info"
              :key="'C'+$info"
          >
            <dt class="OurClients-InfoInfoItemValue">
              {{ info.rank }}
            </dt>
            <dd
                class="OurClients-InfoInfoItemDescription"
                v-html="info.text"
            ></dd>
          </div>
        </dl>
        <div class="OurClients-Review">
          <img
              v-if="client.info.stars"
              :src="require(`@/assets/images/${client.info.stars}`)"
              alt="stars"
              class="OurClients-ReviewStars"
          >
          <p
              v-for="(review, $review) in client.info.review"
              :key="$review"
              class="OurClients-ReviewText"
              v-html="review"
          ></p>
        </div>
      </div>
    </div>      
  </section>
</template>

<script>
export default {
  name: "OurClientsList",
  props: {
    clients: {
      type: Array,
      default: new Array
    }
  }
}
</script>

<style lang="scss">
.OurClients {
  width: 100%;
  max-width: 1400px;
  margin-right: auto;
  margin-left: auto;  
  transition: opacity 0.5s ease-in-out;
  margin-top: -11vw;

  @include s-xxl {
    margin-top: -156px;
  }

  &:not(&--ready) {
    opacity: 0;
  }

  &-Item {
    display: flex;
    margin-bottom: 2px;
    background-color: #fff;
  }

  &-Logo {
    display: none;
    
    @include s-m {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 34%;
      flex: 0 0 auto;
      padding: 4.17vw 6.98vw 4.17vw 4.17vw;
      background-image: linear-gradient(-90deg, rgba(255, 82, 82, 0.6) -11.99%, rgba(255, 82, 82, 0) 100%);
      text-align: right;
    }

    @include s-xxl {
      padding: 60px 134px 60px 60px;
    }

    :nth-child(2n) > & {
      background-image: linear-gradient(-90deg, rgba(177, 154, 206, 0.6) -11.99%, rgba(255, 82, 82, 0) 100%);
    }

    &:after {
      content: "";
      position: absolute;
      top: 53%;
      right: -1px;
      transform: translate(0, -50%);
      width: 2vw;
      height: 1.5vw;
      border-top: 2.155vw solid transparent;
      border-bottom: 2.155vw solid transparent;
      border-right: 2.31vw solid var(--color-body);

      @include s-xxl {
        width: 28px;
        height: 21px;
        border-width: 30px 32px 30px;
      }
    }    
  }

  &-LogoImg {
    transform: rotate(-16deg);
    width: 9.58vw;

    @include s-xxl {
      width: 166px;
    }
  }

  &-Info {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 7.01vw 5.84vw 7.01vw 10.28vw;
    background-image: linear-gradient(0deg, rgba(255, 82, 82, 0.15) -11.99%, rgba(255, 82, 82, 0) 100%);
    
    :nth-child(2n) > & {
      background-image: linear-gradient(0deg, rgba(177, 154, 206, 0.15) -11.99%, rgba(255, 82, 82, 0) 100%);
    }

    @include s-m {
      width: 66%;
      flex: 0 0 auto;
      padding: 3.13vw 8.23vw 2.6vw 5.63vw;
      background-image: none !important;
    }

    @include s-xxl {
      padding: 44px 116px 36px 80px;
    }

    :first-child > & {
      padding-top: 12vw;

      @include s-m {
        padding-top: 8.57vw;
      }

      @include s-xxl {
        padding-top: 120px;
      }
    }
  }

  &-InfoHeader {
    width: 52%;
    padding-right: 20px;

    @include s-m {
      width: 100%;
      flex: 0 0 auto;
      display: flex;
      align-items: center;
      margin-bottom: 1.72vw;
      padding-right: 0;
    }

    @include s-xxl {
      margin-bottom: 24px;
    }
  }

  &-InfoLogo {
    display: block;
    width: 18.69vw;
    max-width: 80px;
    margin-bottom: 3.27vw;
    box-shadow: 0px 4.29vw 7.14vw rgba(163, 56, 222, 0.15);
    border-radius: 3.5vw;

    @include s-m {
      margin-bottom: 0;
      display: none;
    }
  }

  &-Title {
    width: 60%;
    flex: 0 0 auto;
    margin-bottom: 3.27vw;
    color: #52006B;
    font-weight: 500;
    font-size: 4.21vw;
    line-height: 1;

    @include s-m {
      margin-bottom: 0;
      font-size: 3.65vw;
      font-weight: 300;
    }

    @include s-xxl {
      font-size: 52px;
    }
  }

  &-Flags {
    flex: 0 0 auto;
    display: flex;
    flex-wrap: wrap;
  }

  &-FlagsItem {
    flex: 0 0 auto;
    width: 20px;
    height: 20px;
    margin-right: 4px;
    margin-bottom: 4px;
    background-size: 100% auto;
    background-repeat: no-repeat;

    @include s-m {
      width: 1.88vw;
      height: 1.88vw;      
      margin-right: 0.31vw;
      margin-bottom: 0.31vw;
    }

    @include s-xxl {
      width: 26px;
      height: 26px;
      margin-right: 6px;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &-FlagsInfo {
    margin-top: 16px;
    margin-left: 0.78vw;
    color: #B19ACE;
    font-size: 10px;
    font-style: italic;
    white-space: nowrap;

    @include s-m {
      margin-top: 0;
      font-size: 0.94vw;
    }

    @include s-xxl {
      margin-left: 15px;
      font-size: 13px;
    }
  }

  &-InfoInfo {
    width: 48%;
    flex: 0 0 auto;
    padding: 0;
    margin: 0 0 0 auto;

    @include s-m {
      width: 60%;
      display: flex;
      padding-top: 2.14vw;
      margin-left: 0;
    }

    @include s-xxl {
      display: flex;
      padding-top: 30px;
    }
  }

  &-InfoInfoItem {
    margin-right: 2.08vw;
    color: #FE5555;
    line-height: 1;
    padding-top: 3.97vw;
    padding-bottom: 3.97vw;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);

    @include s-m {
      padding: 0;
      border: none;
    }

    @include s-xxl {
      margin-right: 30px;
    }

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      margin-right: 0;
      border-bottom: none;
      padding-bottom: 0;
    }
  }

  &-InfoInfoItemValue {
    padding: 0;
    margin: 0 0 0.52vw;
    font-size: 7.48vw;
    font-weight: 700;

    @include s-m {
      font-size: 2.81vw;
    }

    @include s-xxl {
      margin: 0 0 7px;
      font-size: 40px;
    }
  }

  &-InfoInfoItemDescription {
    padding: 0;
    margin: 0;
    font-size: 4.21vw;

    @include s-m {
      font-size: 1.56vw;
    }

    @include s-xxl {
      font-size: 22px;
    }
  }

  &-Review {
    width: 100%;
    flex: 0 0 auto;
    padding-top: 4.21vw;

    @include s-m {
      padding-top: 0;
      width: 40%;
    }
  }

  &-ReviewStars {
    width: 16.59vw;
    margin-bottom: 1.29vw;

    @include s-m {
      width: 6.82vw;
      margin-bottom: 0.63vw;
    }

    @include s-xxl {
      width: 95px;
      margin-bottom: 9px;
    }
  }

  &-ReviewText {
    color: #B19ACE;
    font-style: italic;
    font-weight: 300;
    font-size: 12px;

    @include s-m {
      font-size: 1.56vw;
    }

    @include s-xxl {
      font-size: 20px;
    }
  }
}
</style>
