<template>
  <div class="cases">
    <Header />
    <OurClientsList :clients="clients" />
    <FormSection/>
    <Footer/>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Header from "@/components/Header";
import OurClientsList from "@/components/OurClientsList";
import FormSection from "@/components/FormSection";
import Footer from "@/components/Footer";

export default {
  name: "OurClients",
  components: {
    Header,
    OurClientsList,
    FormSection,
    Footer
  },
  computed: {
    ...mapGetters({
      clients: "getOurClients"
    })
  },
  metaInfo: {
    title: 'Our Clients — WakeApp',
    meta: [{
      vmid: 'description',
      name: 'description',
      content:"Our Clients",
    }],
  }
}
</script>
